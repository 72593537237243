import React from "react";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { server, config } from "../../env";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CreateOrderNormal = (props) => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [Type_id, setType_id] = useState("");
  const [Weight, setWeight] = useState(0);
  const [types, setTypes] = useState([]);
  const [loader, setLoader] = useState("");
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
      Country: "US",
    },
    {
      ID: "AL",
      Country: "US",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Country: "US",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Country: "US",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Country: "US",
      Name: "California",
    },
    {
      ID: "CO",
      Country: "US",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Country: "US",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Country: "US",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Country: "US",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Country: "US",
      Name: "Florida",
    },
    {
      ID: "GA",
      Country: "US",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Country: "US",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Country: "US",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Country: "US",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Country: "US",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Country: "US",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Country: "US",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Country: "US",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Country: "US",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Country: "US",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Country: "US",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Country: "US",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
      Country: "US",
    },
    {
      ID: "MN",
      Country: "US",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Country: "US",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Country: "US",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Country: "US",
      Name: "Montana",
    },
    {
      ID: "NC",
      Country: "US",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Country: "US",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Country: "US",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Country: "US",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Country: "US",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Country: "US",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Country: "US",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Country: "US",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
      Country: "US",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
      Country: "US",
    },
    {
      ID: "OR",
      Name: "Oregon",
      Country: "US",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
      Country: "US",
    },
    {
      ID: "PR",
      Country: "US",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Country: "US",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Country: "US",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Country: "US",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Country: "US",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Country: "US",
      Name: "Texas",
    },
    {
      ID: "UT",
      Country: "US",
      Name: "Utah",
    },
    {
      ID: "VA",
      Country: "US",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Country: "US",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Country: "US",
      Name: "Washington",
    },
    {
      ID: "WI",
      Country: "US",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Country: "US",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Country: "US",
      Name: "Wyoming",
    },

    // write canada provinces here
    {
      ID: "AB",
      Country: "CA",
      Name: "Alberta",
    },
    {
      ID: "BC",
      Country: "CA",
      Name: "British Columbia",
    },
    {
      ID: "MB",
      Country: "CA",
      Name: "Manitoba",
    },
    {
      ID: "NB",
      Country: "CA",
      Name: "New Brunswick",
    },
    {
      ID: "NL",
      Country: "CA",
      Name: "Newfoundland and Labrador",
    },
    {
      ID: "NS",
      Country: "CA",
      Name: "Nova Scotia",
    },
    {
      ID: "NT",
      Country: "CA",
      Name: "Northwest Territories",
    },
    {
      ID: "NU",
      Country: "CA",
      Name: "Nunavut",
    },
    {
      ID: "ON",
      Country: "CA",
      Name: "Ontario",
    },
    {
      ID: "PE",
      Country: "CA",
      Name: "Prince Edward Island",
    },
    {
      ID: "QC",
      Country: "CA",
      Name: "Quebec",
    },
    {
      ID: "SK",
      Country: "CA",
      Name: "Saskatchewan",
    },
    {
      ID: "YT",
      Country: "CA",
      Name: "Yukon",
    },
  ]);
  const [activeType, setActiveType] = useState({});

  const [From, setFrom] = useState({
    FromName: "",
    FromStreet: "",
    FromStreet2: "",
    FromCity: "",
    FromState: "",
    FromZip: "",
    FromCompany: "",
    FromCountry: "",
  });
  const [To, setTo] = useState({
    ToName: "",
    ToStreet: "",
    ToStreet2: "",
    ToCity: "",
    ToState: "",
    ToZip: "",
    ToCompany: "",
    ToCountry: "",
  });

  useEffect(() => {
    readLabelTypes();
    getAddresses();
  }, []);

  const getAddresses = async () => {
    await axios
      .get(server + "/api/v1/address/readAll", config)
      .then((res) => {
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setFrom({
      FromName: address.name,
      FromStreet: address.street,
      FromStreet2: address.street2,
      FromCity: address.city,
      FromState: address.state,
      FromZip: address.zip,
      FromCompany: address.company,
      FromCountry: address.country,
    });
  }, [address]);
  useEffect(() => {
    setTo({
      ToName: address1.name,
      ToStreet: address1.street,
      ToStreet2: address1.street2,
      ToCity: address1.city,
      ToState: address1.state,
      ToZip: address1.zip,
      ToCompany: address1.company,
      ToCountry: address1.country,
    });
  }, [address1]);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // find the type of label and max weight

    if (!Type_id || !Weight) return;
    const getPrice = async () => {
      const data = {
        labelType: Type_id,
        weight: Weight,
      };

      await axios
        .post(server + "/api/v1/order/price", data, config)
        .then((res) => {
          setFinalPrice(res.data.price);
        })
        .catch((err) => {
          console.log(err);
          setFinalPrice(0);
        });
    };

    getPrice();
  }, [Type_id, Weight]);

  const createOrder = async (e) => {
    e.preventDefault();
    if (isRequestSent) return;
    setIsRequestSent(true);
    setLoader(<Loader />);

    const data = {
      labelType: e.target.type.value,
      design: e.target.design?.value || "",
      Weight: e.target.Weight.value,
      ToName: To.ToName,
      ToStreet: To.ToStreet,
      ToStreet2: To.ToStreet2,
      ToCity: To.ToCity,
      ToState: To.ToState,
      ToZip: To.ToZip,
      ToCompany: To.ToCompany,
      ToCountry: e.target.ToCountry.value,
      FromName: From.FromName,
      FromStreet: From.FromStreet,
      FromStreet2: From.FromStreet2,
      FromCity: From.FromCity,
      FromState: From.FromState,
      FromZip: From.FromZip,
      FromCompany: From.FromCompany,
      FromCountry: e.target.FromCountry.value,
    };

    await axios
      .post(server + "/api/v1/order/create", data, config)
      .then((res) => {
        toast.success("Order created successfully");
        navigate("/");
        setLoader("");
        setIsRequestSent(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsRequestSent(false);
        setLoader("");
      });
  };

  return (
    <>
      <div className="nk-content">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <form onSubmit={createOrder}>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Type</label>
                            <select
                              name="type"
                              id=""
                              className="form-control"
                              onChange={(e) => {
                                setType_id(e.target.value);

                                setActiveType(
                                  types.find((type) => {
                                    return type._id === e.target.value;
                                  }).uid
                                );
                              }}
                            >
                              <option value="">Select type</option>
                              {types.map((type) => (
                                <option key={type._id} value={type._id}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {activeType === "priority" && (
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Template</label>
                              <select
                                required
                                name="design"
                                className="form-control"
                              >
                                <option value=""> Select type</option>
                                <option value="normal">Pitney Bowes</option>
                                <option value="indica">Indica</option>
                                <option value="stamps">Stamps</option>
                                <option value="evs">EVS</option>
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="order-id" className="form-label">
                              Weight
                            </label>
                            <input
                              type="number"
                              name="Weight"
                              id="Weight"
                              className="form-control"
                              placeholder="Weight"
                              required
                              onChange={(e) => {
                                setWeight(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-center">From</h2>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Select a saved address</label>
                        <select
                          name="address"
                          id=""
                          className="form-control"
                          onChange={(e) => {
                            setAddress(
                              addresses.find((a) => a._id === e.target.value)
                            );
                          }}
                        >
                          <option value="">Select address</option>
                          {addresses.map((address) => (
                            <option key={address._id} value={address._id}>
                              {address.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter name"
                          name="FromName"
                          value={From.FromName}
                          onChange={(e) => {
                            setFrom({ ...From, FromName: e.target.value });
                          }}
                          required
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Company</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter company name (optional)"
                          name="FromCompany"
                          value={From.FromCompany}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Street</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromAddress"
                          placeholder="Address"
                          name="FromStreet"
                          value={From.FromStreet}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                          required
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Street 2 (optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromAddress2"
                          placeholder="Address line 2"
                          name="FromStreet2"
                          value={From.FromStreet2}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">ZIP Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromzipCode"
                          placeholder="ZipCode"
                          name="FromZip"
                          required
                          value={From.FromZip}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromCity"
                          placeholder="City"
                          name="FromCity"
                          required
                          value={From.FromCity}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">State</label>
                        <select
                          name="FromState"
                          id="FromState"
                          className="form-control"
                          required
                          value={From.FromState}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select state</option>
                          {activeType.uid === "ups_standard" ||
                          activeType.uid === "ups_express" ||
                          activeType.uid === "ups_express_saver" ||
                          activeType.uid === "ups_express_early" ||
                          activeType.uid === "ups_expedited"
                            ? states
                                .filter((state) => state.Country === "CA")
                                .map((state, index) => (
                                  <option key={index} value={state.ID}>
                                    {state.Name}
                                  </option>
                                ))
                            : states
                                .filter((state) => state.Country === "US")
                                .map((state, index) => (
                                  <option key={index} value={state.ID}>
                                    {state.Name}
                                  </option>
                                ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Country</label>
                        <select
                          className="form-control"
                          name="FromCountry"
                          placeholder="Country"
                          required
                          disabled
                        >
                          {activeType.uid === "ups_standard" ||
                          activeType.uid === "ups_express" ||
                          activeType.uid === "ups_express_saver" ||
                          activeType.uid === "ups_express_early" ||
                          activeType.uid === "ups_expedited" ? (
                            <option value="CA" selected>
                              Canada
                            </option>
                          ) : (
                            <option value="US" selected>
                              United States
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-center">To</h2>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Select a saved address</label>
                        <select
                          name="address"
                          id=""
                          className="form-control"
                          onChange={(e) => {
                            setAddress1(
                              addresses.find((a) => a._id === e.target.value)
                            );
                          }}
                        >
                          <option value="">Select address</option>
                          {addresses.map((address) => (
                            <option key={address._id} value={address._id}>
                              {address.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter name"
                          name="ToName"
                          required
                          value={To.ToName}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Company</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter company name (optional)"
                          name="ToCompany"
                          value={To.ToCompany}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Street</label>
                        <input
                          type="text"
                          className="form-control"
                          id="toAddress"
                          placeholder="Address"
                          name="ToStreet"
                          required
                          value={To.ToStreet}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Street 2 (optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ToStreet2"
                          placeholder="Address line 2"
                          name="ToStreet2"
                          value={To.ToStreet2}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">ZIP Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ToZip"
                          placeholder="Zip Code"
                          name="ToZip"
                          required
                          value={To.ToZip}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ToCity"
                          placeholder="City"
                          name="ToCity"
                          required
                          value={To.ToCity}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">State</label>
                        <select
                          name="ToState"
                          id="ToState"
                          className="form-control"
                          required
                          value={To.ToState}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        >
                          <option value="">Select state</option>
                          {activeType.uid === "ups_standard" ||
                          activeType.uid === "ups_express" ||
                          activeType.uid === "ups_express_saver" ||
                          activeType.uid === "ups_express_early" ||
                          activeType.uid === "ups_expedited"
                            ? states
                                .filter((state) => state.Country === "CA")
                                .map((state, index) => (
                                  <option key={index} value={state.ID}>
                                    {state.Name}
                                  </option>
                                ))
                            : states
                                .filter((state) => state.Country === "US")
                                .map((state, index) => (
                                  <option key={index} value={state.ID}>
                                    {state.Name}
                                  </option>
                                ))}
                        </select>
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Country</label>
                        <select
                          className="form-control"
                          name="ToCountry"
                          placeholder="Country"
                          required
                          disabled
                        >
                          {activeType.uid === "ups_standard" ||
                          activeType.uid === "ups_express" ||
                          activeType.uid === "ups_express_saver" ||
                          activeType.uid === "ups_express_early" ||
                          activeType.uid === "ups_expedited" ? (
                            <option value="CA" selected>
                              Canada
                            </option>
                          ) : (
                            <option value="US" selected>
                              United States
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* show balance and button to create order */}
              <div className="d-flex justify-content-end mt-5">
                <button
                  className="btn btn-primary btn-xl "
                  type="submit"
                  // disabled={isDisabled}
                >
                  Create Order ${finalPrice} {loader}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CreateOrderNormal;
